import classNames from "classnames";
import { ChangeEvent, forwardRef, HTMLInputTypeAttribute } from "react";
import { Subtitle } from "./text";

interface InputProps {
  label?: string;
  placeholder?: string;
  className?: string;
  type?: HTMLInputTypeAttribute;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string | number;
  error?: string;
  readOnly?: boolean;
  variant?: "primary" | "secondary";
  ref?: any;
  autoComplete?: string;
}

const Input = forwardRef(
  (
    {
      label,
      placeholder,
      className,
      type,
      onChange,
      name,
      value,
      error,
      readOnly = false,
      variant = "primary",
      autoComplete,
    }: InputProps,
    ref
  ) => (
    <div className={`flex flex-col gap-2 md:gap-3 ${className}`}>
      {label && <Subtitle>{label}</Subtitle>}

      <input
        ref={ref as any}
        id={label}
        autoComplete={autoComplete}
        placeholder={placeholder}
        className={classNames(
          "text-field w-full h-[40px] border border-[#E5E7EB] focus:border-[#60A5FA] focus:shadow-[#3B82F640]/25 shadow shadow-[#03071229] pl-5 p-4 rounded-md outline-none text-[12px] md:text-[14px]",
          {
            "bg-[#F9FAFB]": variant === "secondary",
            "bg-[#F9FAFB] border-[1px] solid": variant === "primary",
            error: error,
          }
        )}
        onChange={onChange}
        value={value}
        type={type === "number" ? "text" : type}
        name={name}
        readOnly={readOnly}
      />

      {error && (
        <span className='error-text text-[#FF0101] text-[12px]'>{error}</span>
      )}
    </div>
  )
);

export default Input;
