import AuthGuard from "../utils/route-guard/auth-guard";

// dashboard routing
import Dashboard from "../pages/dashboard";
import EditProfile from "../pages/dashboard/edit-profile";
import { Outlet } from "react-router-dom";
import Payout from "../pages/dashboard/wallet/payout";
import Details from "../pages/dashboard/withdrawals/details";
import MainDetails from "../pages/dashboard/wallet/details";
import Delete from "../pages/dashboard/delete";

// =======|| MAIN ROUTING ||======== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      {/* <DashboardLayout /> */}
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/dashboard/profile",
      element: <EditProfile />,
    },
    {
      path: "/user/withdraw",
      element: <Payout />,
    },
    {
      path: "/withdrawal/details/:id",
      element: <Details />,
    },
    {
      path: "/wallet/details/:id",
      element: <MainDetails />,
    },
    // { path: "/delete-account", element: <Delete /> },
  ],
};

export default MainRoutes;
